<template>
    <Task :isAdmin="false" />
</template>
<script>
import Task from '@/components/task/Index'
export default {
    components: {
        Task
    }
}
</script>